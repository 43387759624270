.mensagens-nao-lidas {
    color: red;
    animation: blink 1s;
    animation-iteration-count: 3;
}

.tab-mensagens-nao-lidas {
    background: rgba(255, 105, 105, 0.17) !important;
    animation: blink 1s;
    animation-iteration-count: 3;
}

@keyframes blink { 50% { color: rgba(0, 0, 0, 0.54); background: none; }  }
