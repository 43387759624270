:root {
  --theme-primary: #00598b;
  --theme-primary-dark: #1c4e78;
  --theme-secondary: #018fd7;
  --theme-grey: #939496;
  --theme-grey-light: #f5fafe;
  --theme-grey-dark: #666464;
  --divider: #b0b1b3;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
  background: #f2f3f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: var(--theme-primary) !important;
  color: #ffffff !important;
}

.slashed {
  position: absolute;
  height: 0;
  width: 67%;
  border-radius: 8px;
  border-top: 3px solid #065ba3c2;
  transform: rotate(-28deg);
  top: 20px;
}

.dialog-screen-share-title {
  background: var(--theme-primary) !important;
  color: white;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 6;
}

.menuOpen {
  display: block;
}

.menuClosed {
  display: none;
}

@media screen and (max-width: 960px) {
  .menuClosed {
    display: block;
  }
  .menuOpen {
    display: none;
  }
}
