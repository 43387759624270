.card-info.card-info-chat .card-info-chat-wrapper .div-mensagens-wrapper {
    height: calc(65vh - .25rem) !important;
}

.card-info.card-info-chat .card-info-chat-wrapper {
    height: 65vh !important;
    max-height: 65vh !important;
}

.card-info.card-info-chat {
    max-height: 65vh !important;
}

.green-dot {
    height: 0.7rem;
    width: 0.7rem;
    background-color: green;
    border: 1px solid #9e9e9e;
    border-radius: 50%;
    display: inline-block;
}

.red-dot {
    height: 0.7rem;
    width: 0.7rem;
    background-color: #ff4a4a;
    border: 1px solid #9e9e9e;
    border-radius: 50%;
    display: inline-block;
}
